import React, {useState, useEffect} from 'react'
import {Link, useHistory} from 'react-router-dom'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useDispatch, useSelector} from 'react-redux'
import {FormattedMessage, injectIntl} from 'react-intl'
import {useSnackbar} from 'notistack'
import {actions} from '../_redux/authRedux'
import * as selectors from '../_redux/selectors'
import * as auth from 'app/modules/Auth/_redux/authRedux.js'

import {CityMatchLogo} from 'app/components/images'

import {authService} from 'app/services'

const initialValues = {
  email: '',
  password: '',
}

function Login(props) {
  const {intl} = props

  const {enqueueSnackbar} = useSnackbar()

  const [loading, setLoading] = useState(false)

  const history = useHistory()

  const hasExpiredToken = useSelector(selectors.hasExpiredToken)

  useEffect(() => {
    if (hasExpiredToken) {
      enqueueSnackbar('Sessão expirada, por favor realize o login novamente', {
        variant: 'warning',
      })
    }
  }, [enqueueSnackbar, hasExpiredToken])

  const dispatch = useDispatch()

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Email invalido')
      .required(
        intl.formatMessage({
          id: 'AUTH.LOGIN.VALIDATION.REQUIRED_FIELD',
        }),
      ),
    password: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.LOGIN.VALIDATION.REQUIRED_FIELD',
      }),
    ),
  })

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid'
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid'
    }

    return ''
  }

  const setAuthState = async ({token, contract_accepted}) => {
    dispatch(actions.login(token))
    if (!contract_accepted) {
      dispatch(auth.actions.terms(false))
    }
    history.push('/auth/twofactor')
  }

  const onSubmit = async ({email, password}, {setStatus, setSubmitting}) => {
    try {
      setLoading(true)

      const response = await authService.loginCheck({
        username: email,
        password,
      })

      if (response.token) {
        setAuthState(response)
      } else {
        throw new Error('AUTH.LOGIN.INVALID_PASSWORD')
      }
    } catch (error) {
      setStatus(
        intl.formatMessage({
          id: error.message || 'AUTH.LOGIN.INVALID_PASSWORD',
        }),
      )

      setSubmitting(false)

      setLoading(false)
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit,
  })

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      <div className="text-center mb-10 mb-lg-20">
        <CityMatchLogo />
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.LOGIN.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
          <FormattedMessage id="AUTH.LOGIN.SUBTITLE" />
        </p>
      </div>
      <form onSubmit={formik.handleSubmit} className="form fv-plugins-bootstrap fv-plugins-framework">
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email"
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses('email')}`}
            name="email"
            {...formik.getFieldProps('email')}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Senha"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses('password')}`}
            name="password"
            {...formik.getFieldProps('password')}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <Link to="/auth/forgot-password" className="text-dark-50 text-hover-primary my-3 mr-2" id="kt_login_forgot">
            {intl.formatMessage({id: 'AUTH.LOGIN.FORGOT_PASSWORD_BUTTON'})}
          </Link>
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={formik.isSubmitting}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}>
            <span>{intl.formatMessage({id: 'AUTH.LOGIN.SIGN_IN_BUTTON'})}</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
    </div>
  )
}

export default injectIntl(Login)
