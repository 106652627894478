import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import {storageService} from 'app/services'

import actionTypes from './actionTypes'

const initialAuthState = {
  user: null,
  authToken: null,
  hasExpiredToken: false,
  twoFactor: false,
  terms: true,
}

const reducer = persistReducer(
  {
    storage,
    key: storageService.storageKeys.STORAGE_KEYS.TOKEN,
    whitelist: ['authToken'],
  },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const {authToken} = action.payload

        return {authToken, user: undefined, twoFactor: false, terms: true}
      }

      case actionTypes.Register: {
        const {authToken} = action.payload

        return {authToken, user: undefined, twoFactor: false, terms: true}
      }

      case actionTypes.Logout: {
        return {...initialAuthState, hasExpiredToken: action?.payload?.hasExpiredToken || false}
      }

      case actionTypes.UserLoaded: {
        const {user} = action.payload
        return {...state, user}
      }

      case actionTypes.SetUser: {
        const {user} = action.payload
        return {...state, user: user}
      }

      case actionTypes.TwoFactor:
        const {twoFactor} = action.payload
        return {...state, twoFactor: twoFactor}

      case actionTypes.Terms:
        const {terms} = action.payload
        return {...state, terms: terms}

      default:
        return state
    }
  },
)

export default reducer
